import { useSessionStore } from "@/stores/session";
import { useGetStartedStore } from "@/stores/getStarted";

export default () => {
  /**
   * opens the auth popup when Force_Register
   * is found in the extrafields of a content
   * could be episode level
   * Matrx: if this is true, clicking on an episode/play button
   * should open the login popup
   */
  const isForceRegister = (media) => {
    if (media.extrafields?.length === 0) return false;
    const hasForceRegister = media.extrafields.find(
      (e) => e.name == "Force_Register"
    );

    if (hasForceRegister) {
      getStarted.openModal();
      return true;
    }
  };

  const isPlusNeedPopup = (media) => {
    const auth = useSessionStore();
    const getStarted = useGetStartedStore();
    //if media has plus property，guest user click need login
    if (media.isPlus && !auth.isAuthenticated) {
      getStarted.openModal();
      return true;
    }
    return false;
  };
  const isUnAuthPopup = () => {
    const auth = useSessionStore();
    const getStarted = useGetStartedStore();
    //if guest user click need login
    if (!auth.isAuthenticated) {
      getStarted.openModal();
      return true;
    }
    return false;
  };
  return {
    isForceRegister,
    isUnAuthPopup,
    isPlusNeedPopup,
  };
};
