import { DateTime } from "luxon";
import { ERROR_CODES } from "@/constants";

/*
 * processes an array of objects, extracts their name properties,
 * adds a "·" separator after each name (except for the last one),
 * and then flattens the resulting array to get
 * a single array of strings with the names and separators.
 */
const buildGenreList = (data, contentType) => {
  if (!data) return [];

  if (contentType == "Season") {
    return data.series.genres.map((g) => g.name);
  }

  const { genreEntityList, genres } = data.videoAsset;
  return (genreEntityList || genres).map((g) => g.name);
};

/**
 * @function fetchTrailerPlayingInfo
 * @returns Promise
 * @docs https://adtv-dev.daypop.ai/api/biz/doc.html#/default/Video/GetTrailer
 */
const fetchTrailerPlayingInfo = async (params) => {
  return await useGetMethod("/api/biz/video/trailer/info", params);
};

const fetchTrailer = async (media) => {
  if (media?.trailerDuration <= 0 || !media?.trailerDuration) return null;
  let params = {};
  switch (media?.type) {
    case "Movie": {
      params = {
        type: "Movie",
        assetExternalId: media?.assetExternalId,
      };
      break;
    }
    case "Season": {
      params = {
        type: "Season",
        externalSeasonId: media?.externalSeasonId || media?.seasonExternalId,
      };
      break;
    }
    case "Series": {
      params = {
        type: "Series",
        externalSeriesId: media?.externalSeriesId || media?.seriesExternalId,
      };
      break;
    }

    default: {
      return null;
    }
  }

  return await fetchTrailerPlayingInfo(params);
};

const fetchSeasonTrailer = async ({ externalSeasonId }) => {
  if (!externalSeasonId) return null;
  const response = await fetchTrailerPlayingInfo({
    type: "Season",
    externalSeasonId,
  });
  if (response) {
    const output = {
      ...response,
      ...response.attachmentPlayingInfo,
    };

    return output;
  } else {
    return null;
  }
};

/**
 * wrapper composable to fetch an asset, keeping it here
 * so the logic is contained.
 * hero store, midpagebanner, popup thumbnail, detail page
 * can call on this function to get asset info
 *
 * content type is a string that represents
 * the type of content being requested
 * possible values:
 *
 * Movie, Season, Program, Live etc
 *
 * media is a string that represents the name of media being requested
 * possible values:
 *
 * Avatar, Game-of-thrones-season-1 etc...
 * options object should contain
 *  video_external_id
 *  assetExternalId
 *  contentType
 */

function buildDetailQuery({
  externalContentId,
  assetExternalId,
  externalSeriesId,
  type,
  contentType,
}) {
  const query = {
    type,
    contentType: contentType || type,
    client: "json",
  };

  if (type == "Movie") {
    Object.assign(query, {
      externalContentId,
      assetExternalId,
    });
  }

  if (["Season", "Series"].includes(type)) {
    Object.assign(query, { externalSeriesId });

    if (type == "Season") {
      Object.assign(query, { type: "Series" });
    }

    if (type == "Series" && contentType == "Podcast") {
      Object.assign(query, { contentType: "Series" });
    }
  }

  return query;
}

async function fetchDetail({
  externalContentId,
  externalSeriesId,
  assetExternalId,
  type,
  contentType,
}) {
  const query = buildDetailQuery({
    externalContentId,
    assetExternalId,
    externalSeriesId,
    type,
    contentType,
  });

  const response = await useGetMethod("/api/biz/video/aggregate/detail", query);

  return {
    ...response,
    images: useMediaAssets(response),
  };
}

async function fetchPlayingInfo(
  video_external_id,
  asset_external_id,
  extraQuery = {}
) {
  const { isUnAuthPopup } = useNeedLoginPopup();
  const timestamp = DateTime.now().toMillis();

  const query = {
    resume: true,
    url_session_id: timestamp,
    video_external_id,
    asset_external_id,
    ...extraQuery,
  };
  const { status, code, msg, response, tracing_id } = await useGetMethod(
    "/api/biz/video/playinfo",
    query,
    {
      raw: true,
    }
  );

  if (status !== 200 && code !== "SUCCESS") {
    // TODO: handle session expired

    const errorObj = {
      status,
      code,
      msg,
      tracingId: tracing_id,
    };
    console.error("error fetching playing info", errorObj);
    if (status === ERROR_CODES.FORBIDDEN_ACCESS) {
      isUnAuthPopup();
    }
    throw errorObj;
  }

  return response;
}

async function fetchEpisodesSsr(externalId, query) {
  const response = await useGetMethod("/api/biz/tv/show/episodes", {
    season_external_id: externalId,
    client: "json",
    ...query,
  });

  return response;
}

async function fetchEpisodes(externalId, query) {
  const response = await useGetMethod("/api/biz/tv/show/episodes", {
    season_external_id: externalId,
    client: "json",
    ...query,
  });

  return {
    ...response,
    contents: response.contents.map((r) => {
      const {
        assetExternalId,
        tvShowReference: {
          seasonExternalId,
          episodeNumber,
          seasonNumber,
          seriesExternalId,
        },
      } = r;

      return {
        ...r,
        images: useMediaAssets(r),
        query: {
          video_external_id: assetExternalId,
          externalContentId: assetExternalId,
          externalSeriesId: seriesExternalId,
          externalSeasonId: seasonExternalId,
          episodeNumber,
          seasonNumber,
          type: "Series",
        },
      };
    }),
  };
}

/**
 * only call this API when authenticated!
 *
 * fetches the watch progress and history for provided episode ids
 */
async function fetchEpisodesHistory(episodeIds) {
  const res = await useGetMethod("/api/biz/video/history/season/episodes", {
    contentId: episodeIds.join(","),
  });

  return res;
}

async function fetchChannels(query) {
  const channels = await useGetMethod("/api/biz/live/channel/list", query);

  return channels.map((c) => {
    const programs = c.programs.map((p) => {
      return {
        ...p,
        images: useMediaAssets(p),
      };
    });

    return {
      ...c,
      programs,
    };
  });
}

export default function () {
  return {
    fetchDetail,
    fetchTrailer,
    fetchTrailerPlayingInfo,
    fetchSeasonTrailer,
    fetchPlayingInfo,
    fetchEpisodes,
    fetchEpisodesSsr,
    fetchEpisodesHistory,
    fetchChannels,
  };
}
